export function setGlobals() {
    const channel = sessionStorage.getItem('Channel');

    const lang = sessionStorage.getItem('Language') || 'en';
    const US = '-us'
    document.documentElement.lang = lang + US

    if (
      typeof window != 'undefined' &&
      (typeof window.appNameForSiteCat == 'undefined' ||
        typeof window.uxNameForSiteCat == 'undefined')
    ) {
      window.appNameForSiteCat = channel === 'USBAPP' ? 'MBL' : 'OLB';
      window.uxNameForSiteCat = channel === 'USBAPP' ? 'touch' : 'desktop';
    }
}

const onEnv = (env) => {
  const domain = window.location.origin
  return domain.split('.')[0].indexOf(env) !== -1
}

const isOnProd = () => !onEnv('localhost') && !onEnv('it') && !onEnv('uat') && !onEnv('dev')

export function loadUtagScript(scriptRef) {
  const uTagURL = '//tags.tiqcdn.com/utag/usbank/digital-banking/'
  let uTagEnvUrl = 'dev/'

  if (isOnProd()) {
    uTagEnvUrl = 'prod/'
  }
  const scriptSrc = uTagURL + uTagEnvUrl + scriptRef
  const uTagScript = document.createElement('script')
  window.utag_cfg_ovrd = { noview: true }
  uTagScript.src = scriptSrc
  uTagScript.defer = true
  document.getElementsByTagName('head')[0].appendChild(uTagScript)
}
