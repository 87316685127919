import React, { useEffect, useState } from "react";
import MicroAppLoader from '@usb-ui-tools/micro-app-loader';
import { APPLICATION_ENABLED_FEATURES, ACCOUNT_LIST_QUERY } from '../graphql'
import { fetchWrapper } from '../hooks/fetchWrapper'
import { invokeComponentLevelLog } from '../shared/logger'
import { getApplicationEnableFeatureFlag, tsToken, getLoginURLWithParams, setCustomerType } from '../shared/utils/servicesUtils'
import { safeResponse } from "../config/connections";

import { useLocation } from "react-router-dom";
import { BLEND_CLIENT } from '../Constants/index';
import BridgePage from "../pages/BridgePage";
import {loadUtagScript} from '../tools'
const Authenticate = () => {
  const uRef = React.useRef(null)
  const loginURL = getLoginURLWithParams()
  const [isOpenSSO, setIsOpenSSO] = React.useState(false)
  const [cwkillswitch, setCWKillSwitch] = useState(true)
  const [isBridge, setBridge] = useState(false)

  const { search } = useLocation();
  const client = new URLSearchParams(search).get("client")
  const clientName = (client && typeof client === 'string') ? client.toUpperCase() : null
  const isClientBLEND =  clientName === BLEND_CLIENT

  async function getAccountDetails() {
    const result = safeResponse()
    const featureFlagsResponse = await fetchWrapper(null, null, APPLICATION_ENABLED_FEATURES, '', setIsOpenSSO)
    const applicationFeatureFlags = featureFlagsResponse?.applicationEnabledFeatures?.features
    const cwKS = getApplicationEnableFeatureFlag(applicationFeatureFlags, 'W_AUCW')
    setCWKillSwitch(cwKS)
    invokeComponentLevelLog('IdentifyKillSwitch/fromApi', { applicationSessionFlag: JSON.stringify(applicationFeatureFlags) })
    const identifyCustomerResponse = await fetchWrapper(null, null, ACCOUNT_LIST_QUERY, result, setIsOpenSSO)
    const customers = identifyCustomerResponse?.getCustomerService?.customers
    setCustomerType(customers)
    setIsOpenSSO(true)
  }

  useEffect(()=> {
    loadUtagScript('utag.js');
    loadUtagScript('utag.sync.js');
  },[])
  useEffect(() => {
    if (!tsToken && isClientBLEND) {
      setBridge(true)
    }else if(!tsToken){
      window.location = loginURL
    } else {
      getAccountDetails()
    }
  }, [tsToken])

  return (
    <div>
      <React.Suspense fallback={<div>...</div>}>
        {isBridge && <BridgePage/> }
        {isOpenSSO && <MicroAppLoader
          elementRef={uRef}
          id="genericsso"
          manifestPath={`${window.location.origin}/digital/auth/genericsso/manifest.json`}
          isDomainPrefixedToManifestPath={false}
          namespace="USBAuthGenericSSOUI"
          appProps={{
            isReportingEnabled: true,
            cwSwitch: cwkillswitch,
            configApiHeaders: {
              tenantId: "USB",
              applicationId: "RETAIL",
              channelId: "web",
              appVersion: "am-genericsso-1.2"
            }
          }}
        />}
      </React.Suspense>
    </div>
  );
};

export default Authenticate;
