import React, { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import "@usb-sharedauth/lib-auth-ui-header/dist/styles/Header.css";
import "@usb-sharedauth/lib-auth-ui-logo/dist/styles/HeaderLogo.css";
import "@usb-shield/react-button/dist/library/styles/index.css";
import Header from "@usb-sharedauth/lib-auth-ui-header/dist/Header";
import USBButton from "@usb-shield/react-button";
import * as Constants from "../Constants";
import {  setGlobals, LoadTealiumEvent } from '../tools';
import Spinner from "./spinner";
import { getEnvType, getUrlParamValue, logInfo, logError, initLogger } from "../shared/utils/commonUtils";
const logo = {
  mainLogo: `${process.env.PUBLIC_URL}/assets/images/idc_usbank_logo.svg`,
  mainLogoUrl: "",
};
const getTealiumEvent = (type,source) =>{
  const data ={
    clientID: 'Blend',
    clientName: 'Blend',
    channel: 'OLB',
    deviceType: 'desktop',
    datasource: source
  }
  const pageData = `usb:mortgage login:login or directly to apply`
  const eventData = `usb:mortgage login:login or directly to apply selection choose ${type}`
  const event = source === 'pageView' ? {currentPage:pageData} : {onClickEvent: eventData}
  LoadTealiumEvent({
    ...data,
    ...event
  })
}
const loanType = {
  HE: "HELOC",
  ML: "MORTGAGE",
  HL: "HELOAN",
  SR: "HELOAN",
};
const BridgePage = () => {
  const { search } = useLocation();
  const [showLoader, setLoader] = useState(false);
  const params = new URLSearchParams(search);
  const party = getUrlParamValue(Constants.PARTY) || Constants.PRIMARY;
  const product = getUrlParamValue(Constants.PRODUCT);
  const type = getUrlParamValue(Constants.TYPE);
  const referrerId = getUrlParamValue(Constants.REFERRERID)
  const currentEnv = getEnvType();
  let productExist = !!Object.keys(loanType).includes(product)
  let keys = [...params.keys()];
  let productInParams = keys.includes('product')
  const handleClickSignIn = (e) => {
    e.preventDefault();
    logInfo(Constants.BRIDGE_COMP_PAGE, Constants.BLEND_BRIDGE_SIGNIN_CLICK, '', '');
    window.location.href = `${window.location.origin}/auth/login/?${params}`;
    getTealiumEvent("login","onClick")
  };
  const getLoanType = (product) => {
    return (product && productExist) ? loanType[product] : loanType["ML"]
  }
  const getProductValue = (product) => {
    return product !== null && product !== "" && product !== undefined ? product : "";
  }
  const getRefId = () => {
    return referrerId || Constants.DEFAULTREFID;
  }
  const getSmartRef = (productValue) => {
    return productValue && productValue === Constants.SMARTREFINANCEPRODUCT;
  }
  const invalidProductRedirect = (urlVal,signupParams) => {
    const Url = currentEnv === Constants.UAT_ENV ? Constants.UAT_SIGNUP_URL : Constants.PROD_SIGNUP_URL;
    const redirectURL = currentEnv === Constants.IT_ENV ? Constants.IT_SIGNUP_URL : Url;
    window.location.href = `${redirectURL}/?${urlVal}&${signupParams}`;
  }
  const getRedirectionURL = (isSmartRef,signupParams,urlVal) => {
    if (currentEnv === Constants.IT_ENV) {
      urlVal = isSmartRef
        ? `${urlVal}&applicationTemplateId=${Constants.BLEND_APPLICATION_TEMPLATE_ID_IT}`
        : `${urlVal}`;
      window.location.href = `${Constants.IT_SIGNUP_URL}/?${urlVal}&${signupParams}`;
    } else if (currentEnv === Constants.UAT) {
      urlVal = isSmartRef
        ? `${urlVal}&applicationTemplateId=${Constants.BLEND_APPLICATION_TEMPLATE_ID_UAT}`
        : `${urlVal}`;
      window.location.href = `${Constants.UAT_SIGNUP_URL}/?${urlVal}&${signupParams}`;
    } else {
      urlVal = isSmartRef
        ? `${urlVal}&applicationTemplateId=${Constants.BLEND_APPLICATION_TEMPLATE_ID_PROD}`
        : `${urlVal}`;
      window.location.href = `${Constants.PROD_SIGNUP_URL}/?${urlVal}&${signupParams}`;
    }
  }
  const borrowerRedirect = (product) => {
    const loanValue = getLoanType(product);
    const productValue = getProductValue(product);
    const isSmartRef = getSmartRef(productValue)
    const refId = getRefId();
    for (let key of keys) {
      let removeList = ["party", "type", "product", "client"];
      let queryKey = key?.toLowerCase();
      if (removeList.includes(queryKey)) {
        params.delete(queryKey);
      }
    }
    const signupParams = params.toString();
    let urlVal = `referrerId=${refId}&journeyOption=&loanType=${loanValue}`;

    if (!productExist && productInParams) {
      logError(Constants.BRIDGE_COMP_PAGE, Constants.PRODUCT_NOT_EXIST, 'Invalid Product', '');
      invalidProductRedirect(urlVal,signupParams);
    } else {
      getRedirectionURL(isSmartRef,signupParams,urlVal);
    }
  };
  const redirect = () => {
      if (type === Constants.SIGNIN && (party === Constants.PRIMARY || party === Constants.COBORROWER)) {
        logInfo(Constants.BRIDGE_COMP_PAGE, Constants.BRIDGE_SIGNIN_REDIRECT, 'SignIn Onload Redirect', 'Onload Redirect');
        setLoader(true)
        window.location.href = `${window.location.origin}/auth/login/?${params}`;
      } else if (type === Constants.SIGNUP && party === Constants.COBORROWER) {
        logInfo(Constants.BRIDGE_COMP_PAGE, Constants.BRIDGE_SIGNUP_REDIRECT, 'Signup Onload Redirect', 'Onload Redirect');
        borrowerRedirect(product)
      }
  };
  useEffect(() => {
    initLogger()
    logInfo(Constants.BRIDGE_COMP_PAGE, Constants.PAGE_ONLOAD, 'Bridge Page Onload', 'Bridge Page Onload');
    document.title = "U.S. Bank";
    setGlobals();
    getTealiumEvent('','pageView')
    redirect();
  }, []);
  const handleClickStayAsGuest = (e) => {
    e.preventDefault();
    logInfo(Constants.BRIDGE_COMP_PAGE, Constants.BLEND_BRIDGE_SIGNUP_CLICK, '', '');
    getTealiumEvent("apply","onClick")
    borrowerRedirect(product);
  };
  return (
    !showLoader ?
    <div className="bridePage">
      <Header
        logo={logo}
        hideMenu={true}
        showHeaderMenu={false}
        showMenuBar={false}
        showHamburgerNav={false}
      />
      <div id="SSOLoginContainer" className="SSODefaultContainer">
        <div className="headerLeftContentGroupContainer" id="landingPage">
          <div className="lets-get-started">{Constants.BRIDGE_BODY_HEADER}</div>
          <div className="our-online-loan-appl">
            {Constants.BRIDGE_BODY_INFO}
          </div>
          <div>
            <div className="icon-circlecheckmark"></div>
            <div className="pointer-text">
              {Constants.SIMPlE_AND_FAST_APPLICATION}
            </div>
          </div>
          <div>
            <div className="icon-enroll-24px"></div>
            <div className="pointer-text">{Constants.LESS_PAPER_WORK}</div>
          </div>
          <div>
            <div className="icon-clock-24px "></div>
            <div className="pointer-text-third">
              {Constants.SHORT_TIME_APPROVAL}
            </div>
          </div>
          <div className="save-time-by-signing">
            {Constants.BRIDGE_BODY_PARAGRAPH_TEXT}
          </div>
          <div className="dont-have-an-existi">
            {Constants.BRIDGE_BODY_TEXT}
            <span className="dont-have-an-existi-span">
              {Constants.START_AS_A_GUEST}
            </span>
            {Constants.BRIDGE_BODY_TEXT_1}
          </div>
          <div>
            <div className="leftButtonGroupContainer">
              <div className="leftButtonContainer">
                <USBButton
                  type="button"
                  variant="primary"
                  size="large"
                  ariaLabel="test"
                  id={"primary-large"}
                  spacing={{
                    margin: 0,
                  }}
                  addClasses="frontify-anchorButton--secondary"
                  handleClick={handleClickSignIn}
                  name={Constants.BRIDGE_BUTTON_SIGN_IN}
                >
                  {Constants.BRIDGE_BUTTON_SIGN_IN}
                </USBButton>
              </div>
              <div className="leftButtonContainer leftButtonNone">
                <USBButton
                  type="button"
                  variant="secondary"
                  size="large"
                  ariaLabel="test"
                  id={"primary-large"}
                  spacing={{
                    margin: 0,
                  }}
                  addClasses="frontify-anchorButton--secondary"
                  handleClick={handleClickStayAsGuest}
                  name={Constants.BRIDGE_BUTTON_SIGN_UP}
                >
                  {Constants.BRIDGE_BUTTON_SIGN_UP}
                </USBButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footerAlign footerBottom">
        <div className="shieldBanner">
          <div className="usbCopyright pb-5">{Constants.COPYRIGHT_USBANK}</div>
          <div className="usbCopyright pb-5">{Constants.USBANK_ADDRESS_1}</div>
          <div className="usbCopyright">{Constants.USBANK_ADDRESS_2}</div>
        </div>
      </footer>
    </div>
    :<div><Spinner/></div>
  );
};

export default BridgePage;
