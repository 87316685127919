// import logo from './logo.svg';
import React from 'react';
import './App.css';
import './styles/scss/index.scss'
import Authenticate from './components/authenticate.component';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GenericSSO from './components/genericsso.component';

function App() {
  
  return (
    <div className="App" data-test="divComp">
      <Router>
        <Switch>
          <Route exact path="/digital/ssologin/authenticate" component={Authenticate} />
          <Route exact path="/digital/ssologin" component={Authenticate} />
          <Route exact path="/digital/ssologin/gs" component={GenericSSO} />
        </Switch>
      </Router>
    </div>
  );
}


export default App;
