export const APPLICATION_ENABLED_FEATURES = `query {
  applicationEnabledFeatures (finclsrvcftrcde:"W_AUCW", platform: "WEB"){
    features {
      feature
      platform
      enabled
    }
  }
}`

export const ACCOUNT_LIST_QUERY = `query {
  getCustomerService {
    customers {
      language {
        preferenceCode
      }
      customerTypeCode
      customerType
      name {
        fullName
        firstName
      }
    }
  }
}`