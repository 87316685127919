/* eslint-disable no-undef */
// eslint-disable-next-line import/no-unresolved
import logger from '@usb-ui-tools/logger'

const defaultParams = {
  eventType: 'AUTH',
  eventSubType: 'Generic SSO',
  appId: 'react_auth_genericsso_login'
}

const eventSourceDefaultParams = {
  businessCapability: 'GenericSSO-HOST-BC',
  businessFunctionality: 'GenericSSO-HOST-BF'
}

const defaultEventAttributes = {
  qmSession:
    typeof window !== 'undefined' && window.sessionStorage.getItem('qmSession'),
  deviceAppVersion: 'NotApplicable'
}

export const intializeApplicationLogger = () => {
  if (process.env.NODE_ENV !== 'test') {
    logger.init({
      currentSession:
        typeof window !== 'undefined' && window.sharedData?.currentSession,
      advanced: true,
      ...defaultParams,
      ...eventSourceDefaultParams,
      devicesInfo: {
        breakpoints: {
          mobile: 480,
          tablet: 760
        },
        defaultDevice: 'desktop'
      }
    })
  }

  logger.info({
    level: 'info',
    eventSource: {
      ...eventSourceDefaultParams,
      component: 'Generic SSO',
      subComponent: 'Generic SSO Redirect',
      stepName: 'Generic SSO Micro UI Injected'
    },
    eventAttributes: {
      ...defaultEventAttributes
    },
    ...defaultParams
  })
}

export const invokeAPIInfoLog = (
  operationName,
  variables,
  res,
  apiStatusCode,
  duration
) => {
  logger.info({
    level: 'info',
    eventSource: {
      ...eventSourceDefaultParams,
      component: 'REST_API',
      subComponent: 'RESTClientInstance',
      stepName: 'API_REQUEST_SUCESSFUL'
    },
    eventAttributes: {
      ...defaultEventAttributes,
      apiOperationName: operationName,
      apiRequestObject: variables,
      apiResponseObject: res,
      apiStatusCode,
      type: 'API',
      duration
    },
    ...defaultParams
  })
}

export const invokeAPIErrorLog = (
  operationName,
  variables,
  message,
  apiStatusCode,
  apiErrorCode
) => {
  logger.error({
    error: message,
    eventSource: {
      ...eventSourceDefaultParams,
      component: 'REST_API',
      subComponent: 'RESTClientInstance',
      stepName: 'API_REQUEST_FAILED'
    },
    eventAttributes: {
      ...defaultEventAttributes,
      apiOperationName: operationName,
      apiRequestObject: variables,
      apiResponseObject: message,
      apiStatusCode,
      apiErrorCode,
      type: 'API'
    },
    eventStatus: 'ERROR',
    ...defaultParams
  })
}

export const invokeComponentLevelLog = (
  eventSource,
  eventAttributes,
  level = 'info'
) => {
  if (level === 'info') {
    logger.info({
      level,
      eventStatus: 'SUCCESS',
      eventSource: {
        ...eventSourceDefaultParams,
        ...eventSource
      },
      eventAttributes: {
        ...defaultEventAttributes,
        ...eventAttributes
      },
      ...defaultParams
    })
  } else {
    logger.error({
      level,
      eventStatus: 'ERROR',
      eventSource: {
        ...eventSourceDefaultParams,
        ...eventSource
      },
      eventAttributes: {
        ...defaultEventAttributes,
        ...eventAttributes
      },
      ...defaultParams
    })
  }
}

export default {
  intializeApplicationLogger,
  invokeAPIInfoLog,
  invokeAPIErrorLog,
  invokeComponentLevelLog
}
