export const BRIDGE_BODY_HEADER = "Let's get started.";
export const BRIDGE_BODY_TEXT = "Save time applying by logging in to your U.S. Bank account. Don't have an existing online banking account? Select the ";
export const BRIDGE_BODY_TEXT_1 =" option below and enter an email address and password to get started.";
export const START_AS_A_GUEST= "Start as a guest";
export const BRIDGE_BODY_INFO = "Our online experience gives you access to:";
export const SIMPlE_AND_FAST_APPLICATION = "A simpler and faster application."
export const LESS_PAPER_WORK = "Less paperwork.";
export const SHORT_TIME_APPROVAL = "A shorter time to approval."
export const BRIDGE_BODY_PARAGRAPH_TEXT = "Prior to receiving approval, you may be required to provide additional information."
export const BRIDGE_BUTTON_SIGN_IN = "Sign in and start";
export const BRIDGE_BUTTON_SIGN_UP = "Start as a guest";
export const COPYRIGHT_USBANK = "© 2024 U.S. Bank";
export const USBANK_ADDRESS_1 = "800 Nicollet Mall";
export const USBANK_ADDRESS_2 = "Minneapolis, MN  55402";
export const PRIMARY = "PRIMARY";
export const COBORROWER = "COBORROWER";
export const SIGNIN="SIGNIN";
export const SIGNUP="SIGNUP";
export const REFERRERID = "REFERRERID"
export const DEFAULTREFID ="PortalDotCom%40usbank.com"
export const EMPTY_STRING = '';


export const MortgageLoanType = "MORTGAGE";
export const HelocLoanType = "HELOC";
export const HomeEquityLoan = "HELOAN";
export const SmartRefinance = "SMARTREFI";

export const HELOCPRODUCT = "HE";
export const HOMEQUITYPRODUCT = "HL";
export const SMARTREFINANCEPRODUCT = "SR";
export const MORTGAGEPRODUCT = "ML";

export const IT_SIGNUP_URL = "https://usbank-test.beta.blendlabs.com/public/redirect/signup"
export const UAT_SIGNUP_URL = "https://usbank.beta.blendlabs.com/public/redirect/signup"
export const PROD_SIGNUP_URL = "https://mortgageapply.usbank.com/public/redirect/signup"
export const PRIMARY_SIGNUP_URL = "https://mortgageapply.usbank.com/#/milestones"

export const IT_ENV = "it";
export const LOCALHOST_ENV = "localhost";
export const UAT_ENV = "uat";
export const EMP_ENV = "emp";
export const PROD_ENV = "prod";
export const PRODUCTION = "production";
export const ALPHA = "alpha";
export const UAT = "uat";
export const PERF_ENV = "perf1";

export const BLEND_APPLICATION_TEMPLATE_ID_IT = "15509a05-dc8e-44de-95ec-275160aa0fa9"
export const BLEND_APPLICATION_TEMPLATE_ID_UAT = "6ab44373-1e12-4dbe-99a2-a2857b40c14f"
export const BLEND_APPLICATION_TEMPLATE_ID_PROD = "4512ca04-63b5-4d67-a757-ccbc07057a9a"

export const BLEND_CLIENT = "BLEND"
export const CLIENT = "CLIENT"
export const PARTY= "PARTY"
export const PRODUCT = "PRODUCT"
export const TYPE = "TYPE"


export const LOGGER_BUSINESS_CAPABILITY = 'businessCapability'
export const LOGGER_BUSINESS_FUNCTIONALITY = 'businessFunctionality'
export const LOGGER_STEPNAME = 'stepName'
export const LOGGER_EVENT_STATUS = 'eventStatus'
export const LOGGER_ACTION = 'action'

export const BRIDGE_COMP_PAGE = 'BridgePage';
export const PAGE_ONLOAD = 'pageOnload'
export const PRODUCT_NOT_EXIST= 'BridgeError'
export const BRIDGE_SIGNIN_REDIRECT = 'BridgeSignInRedirect'
export const BRIDGE_SIGNUP_REDIRECT = 'BridgeSignUpRedirect'
export const BLEND_BRIDGE_SIGNIN_CLICK = 'BridgeSignInClick'
export const BLEND_BRIDGE_SIGNUP_CLICK = 'BridgeSignUpClick'
