/* eslint-disable */
import { apiGeeDomain, API_CONST } from '../../../config/connections'
import Cookies from "js-cookie";

export const getGraphQLServiceURL = ({ service }) =>
  `${apiGeeDomain}${API_CONST.graphql[service]}`

export const getApplicationEnableFeatureFlag = (applicationFeatureFlags, featureFlag) => {
  let selectedFeatureFlag
  if (applicationFeatureFlags && applicationFeatureFlags !== 'undefined') {
    selectedFeatureFlag = applicationFeatureFlags.find(
      (eachFlag) => eachFlag.feature === featureFlag
    )
  }
  return selectedFeatureFlag?.enabled
}

//--------------------------------------------
// Function parseJWT decodes JWT from transmit.
// Extracts the UserID
//--------------------------------------------
// prettier-ignore

export const tsToken = sessionStorage.getItem("ts:usertkn") ? sessionStorage.getItem("ts:usertkn") : Cookies.get("apply_jwt")

export const parseJwt = (token) => {
  if (!token) return null
  const base64Url = token.split('.')[1]
  const base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(base64)
}

export const getQueryParams = () => {
  let queryParam = new URLSearchParams(window.location.search);
  return Array.from(queryParam).reduce((o, i) => ({ ...o, [i[0]]: i[1] }), 0)
}

export const getSSOParams = () => {
  let policyParams = {
    clientOrDestReq: {}
  };

  let allParams = getQueryParams()

  for (const [key, value] of Object.entries(allParams)) {
    policyParams.clientOrDestReq[key] = decodeURIComponent(value);
  }

  return policyParams
}

export const getLoginURLWithParams = () => {
  let logiURI = `/auth/login?`
  let newString = "";
  let policyParams = getSSOParams()

  for (const [key, value] of Object.entries(policyParams.clientOrDestReq)) {
    newString = newString + `${key}=${value}&`
  }

  newString = newString.slice(0, -1);

  return logiURI + newString
}

const SMB_CUSTOMER_TYPE_CODES = ['B', 'DB', 'E', 'H', 'V']

const SMB_CUSTOMER_TYPE = 'B'

export const setCustomerType = (customers) => {
  if (customers.length > 0) {
    const smbCustomer = customers?.filter(
      (customer) =>
        customer?.customerType === SMB_CUSTOMER_TYPE &&
        SMB_CUSTOMER_TYPE_CODES.includes(customer?.customerTypeCode)
    )
    if (smbCustomer.length > 0) {
      const { customerType, customerTypeCode } = smbCustomer[0]
      sessionStorage.setItem('customerType', customerType)
      sessionStorage.setItem('customerTypeCode', customerTypeCode)
    } else {
      const { customerTypeCode, customerType } = customers[0]
      if (customerTypeCode) {
        sessionStorage.setItem('customerTypeCode', customerTypeCode)
        /*  refactored code and setting customerType as customerTypeCode as per
            the previous version */
        sessionStorage.setItem('customerType', customerType)
      }
    }
  }
}


