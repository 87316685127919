/* eslint-disable no-undef */

const host = window.location.hostname
const getRoutingKey = (defaultKey = null) =>
  defaultKey ?? host.split(/[.,-]/)[0]

const isOnEnv = () => {
  const domainName = host.split(/[.,\\/ -]/)[0]
  const envName =
    domainName === 'onlinebanking2'
      ? domainName
      : domainName.replace(/\d+|:/g, '')

  return envName
}
const routingKeys = {
  preprod: 'preprod',
  onlinebanking2: 'preprod',
  emp: 'emp'
}

const formEndPointsByConvention = () => {
  const defaultEndpoints = {
    apiGee: 'https://api.usbank.com/',
    routingKey: ''
  }

  const environment = isOnEnv()
  switch (environment) {
    case 'localhost':
    case 'dev':
      return {
        apiGee: 'https://it-api.usbank.com/',
        routingKey: getRoutingKey('it5')
      }
    case 'it':
      return {
        apiGee: 'https://it-api.usbank.com/',
        routingKey: getRoutingKey()
      }
    case 'uat':
    case 'pentest':
    case 'perf':
      return {
        apiGee: 'https://alpha-api.usbank.com/',
        routingKey: getRoutingKey()
      }
    case 'emp':
    case 'preprod':
    case 'onlinebanking2':
      return {
        apiGee: 'https://api.usbank.com/',
        routingKey: getRoutingKey(routingKeys[environment])
      }
    default:
      return defaultEndpoints
  }
}

const endpoints =
  typeof window !== 'undefined' ? formEndPointsByConvention() : {}
const { apiGee: url, routingKey } = endpoints
const apiGeeDomain = url
const contentDomain = `https://${endpoints.content}`
const redirectExistingDashboard = `https://${host}/digital/servicing/customer-dashboard`
const redirectEnhancedDashboard = `https://${host}/digital/servicing/enhanced-customer-dashboard`
const tuxUrl = `https://${host}/tux/public/index.html`
const API_CONST = {
  graphql: {
    query: 'customer-management/graphql/v1'
  }
}

const authAndRoutingHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${sessionStorage.getItem('AccessToken')}`,
  routingKey
})
const getHeaders = () => ({
  ...authAndRoutingHeaders(),
  'Application-ID': 'OLB'
})

export {
  API_CONST,
  endpoints,
  apiGeeDomain,
  contentDomain,
  getHeaders,
  redirectEnhancedDashboard,
  redirectExistingDashboard,
  tuxUrl
}

export const safeResponse = () => ({
  getCustomerService: { customers: [] }
})
