import React from 'react';
import './spinner.css';

const Spinner=()=>{
        return (
            <div id="spinner__container">
                <div className="ng-isolate-scope lw__loader" not-busy-remove-classes="lw__loader" id="divLoading">
                    <div className="spinner">
                        <div className="holder">
                            <div className="bar1" />
                            <div className="bar2" />
                            <div className="bar3" />
                            <div className="bar4" />
                            <div className="bar5" />
                            <div className="bar6" />
                            <div className="bar7" />
                            <div className="bar8" />
                            <div className="bar9" />
                            <div className="bar10" />
                            <div className="bar11" />
                            <div className="bar12" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


export default Spinner;
