export const isWindow = () => typeof window != 'undefined'

export const deviceType = !isWindow() ? "touch" : 'desktop'

const globalData = {
  sitePlatform: 'omni',
  siteSection: 'mortgage',
  subSiteSection: 'mortgage login',
  visitorType: `customer`,
  deviceType: deviceType,
  languageType: sessionStorage.getItem("languageType") || "english",
  channel: !isWindow() ? 'MBL' : 'OLB'
}

const timeOut = 500
let pageViewCounter = 0
let timer;
const TealiumEvent = (ad) => {
  if (isWindow() && window.publisherFW) {
    window.publisherFW.publishEvent(ad.datasource, ad)
    clearTimeout(timer)
  } else if (pageViewCounter < 100) {
    pageViewCounter += 1
    timer = setTimeout(() => {
      TealiumEvent(ad)
    }, timeOut)
  }
}

const getSessionLogData = () => {
  let transactionType = 'Regular'
  const logData = JSON.parse(
    typeof sessionStorage !== 'undefined' && sessionStorage.getItem('LogData')
  )

  if (logData?.isDual) {
    transactionType = 'Dual'
  }
  const flowType = logData?.flowType || ''
  return {
    flowType,
    transactionType
  }
}

export function LoadTealiumEvent(reportingData) {
  const { transactionType } = getSessionLogData()
  if (transactionType === 'Dual') {
    globalData.subSiteSection = 'manage transfers:dual'
  }
  TealiumEvent({ ...globalData, ...reportingData })
}

export default LoadTealiumEvent
