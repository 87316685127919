import React from "react";
import MicroAppLoader from '@usb-ui-tools/micro-app-loader';

const GenericSSO = () => {
  const uRef = React.useRef(null)

  return (
    <div>
      <React.Suspense fallback={<div>...</div>}>
        <MicroAppLoader
          elementRef={uRef}
          id="genericsso"
          manifestPath={`${window.location.origin}/digital/auth/genericsso/manifest.json`}
          isDomainPrefixedToManifestPath={false}
          namespace="USBAuthGenericSSOUI"
          appProps={{
            isReportingEnabled: true,
            isTransmitEnabled: true,
            queryParams: { token: "daba4e7e97d2e12d852d58341755d7680abf4375bec216753e62c51cd9cdd511", code: "27003106/MDrvM2fUAb0m3jNpGfBT7yhE" }
          }}
        />
      </React.Suspense>
    </div>
  );
};

export default GenericSSO;
