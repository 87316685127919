/* eslint-disable*/
import { getHeaders } from '../config/connections'
import { invokeAPIErrorLog, invokeAPIInfoLog } from '../shared/logger'
import { getGraphQLServiceURL, parseJwt } from '../shared/utils/servicesUtils'
import {
  redirectExistingDashboard,
  tuxUrl
} from '../config/connections'

function isGraphQLError(res) {
  res.forEach((error) =>
    invokeAPIErrorLog(
      'Graphql',
      '',
      error?.message,
      error?.errorCode,
      error?.errorCode
    )
  )
  return res?.errors
}
async function doHoganDownCheck(res) {
  if (res?.errors[0]?.extensions?.errorCode === '500.1001.4000') {
    invokeAPIErrorLog(
      'Hogan down',
      '',
      res?.[0]?.message,
      res?.[0]?.extensions?.errorCode,
      res?.[0]?.extensions?.errorCode
    )
    // window.location = '/Auth/CustomerDashboardReadOnly.aspx'
  }
  if (setIsOpenSSO) {
    setIsOpenSSO(true)
  }
}

export async function fetchWrapper(url, headers, requestBody, safeResponse, setIsOpenSSO) {
  const graphqlUri = getGraphQLServiceURL({ service: 'query' })
  const defaultHeaders = headers ? headers : getHeaders()
  const queryURL = !url ? graphqlUri : url
  let result = safeResponse

  try {
    // if no url is padded used graphQL end point by default
    const response = await fetch(queryURL, {
      method: 'POST',
      headers: !headers
        ? {
          ...defaultHeaders,
          aftokenvalue: sessionStorage.getItem("AFTokenValue"),
          visitorId:
            typeof window !== 'undefined'
              ? window.visitor?.getMarketingCloudVisitorID()
              : ''
        }
        : defaultHeaders,
      body: JSON.stringify({ "query": requestBody })
    })
    const res = await response.json()
    if (response.ok && !url) {
        result = !res?.data ? isGraphQLError(res) : res?.data
    } else if(response.ok && url) {
        // This is for restAPI because there is no data like graph ql response.
        result = response.ok
        invokeAPIInfoLog(
          "GraphQL KS",
          "",
          res?.ok,
          res?.status
        )
    }else {
      invokeAPIErrorLog(
        'Graphql Fetch',
        '',
        response?.statusText,
        response?.status,
        response?.code
      )
      await doHoganDownCheck(res)
    }
  } catch (err) {
    if (setIsOpenSSO) {
      setIsOpenSSO(true)
    }
    // const parsedJwtTocken = parseJwt(sessionStorage.getItem('ts:usertkn'))
    // if (parsedJwtTocken?.params?.channelId === "mobileweb") {
    //   window.location.href = tuxUrl
    // } else {
    // sessionStorage.setItem('returnURL', redirectExistingDashboard)
    // window.location.href = redirectExistingDashboard
    // }
    invokeAPIErrorLog(err, '', err?.message, '', 999)
  }
  return result
}
