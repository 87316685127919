import logger from '@usb-ui-tools/logger';
import * as Constants from "../../../Constants";
import jslogger from '../../../JSONLoggerConstants.json'

const parseUrl = window.location.origin.split("/")[2];
const arr = [];
const clientObj ={ 'client' : 'Blend'}

export const getEnvType = () => {
    return parseUrl && (parseUrl.startsWith(Constants.IT_ENV) || parseUrl.startsWith(Constants.LOCALHOST_ENV)) ? Constants.IT_ENV : parseUrl.startsWith(Constants.UAT_ENV) ? Constants.UAT_ENV : parseUrl.startsWith(Constants.PERF_ENV) ? Constants.PERF_ENV : Constants.PROD_ENV;
}
export const logInfo = (comp, event, action = '', msg = '') => {
        const arrLoggerDetails = arr[0][comp][event];
        logger.info({
        // @ts-ignore
            eventSource: {
                businessCapability: arrLoggerDetails[Constants.LOGGER_BUSINESS_CAPABILITY],
                businessFunctionality: arrLoggerDetails[Constants.LOGGER_BUSINESS_FUNCTIONALITY],
                component: comp,
                stepName: arrLoggerDetails[Constants.LOGGER_STEPNAME],
                ...clientObj,
            },
            eventStatus: arrLoggerDetails[Constants.LOGGER_EVENT_STATUS],
            eventAttributes: {
                action: action === '' ? arrLoggerDetails[Constants.LOGGER_ACTION] : action,
                message: msg
            },
        });
}

export const logError = (comp, feature, featureDesc, eStatus) => {
        logger.error({
            eventSource: {
                businessCapability: feature,
                businessFunctionality: featureDesc,
                component: comp,
                ...clientObj
            },
            eventStatus: eStatus || 'ERROR',
        });
};
const initLoggerConstants = () => {
    Object.keys(jslogger).forEach((key) => {
        arr.push(jslogger[key]);
    });
};
export const initLogger = () => {
    logger.init({
      appId: 'react_auth_genericsso_login',
      advanced: true,
      eventType: 'AUTH',
      eventSubType: 'Generic SSO',
      currentSession:
        typeof window !== 'undefined'
          ? JSON.parse(sessionStorage.getItem('currentSession'))
          : null,
    });
    initLoggerConstants()
}

export const getUrlParamValue = (queryParam) => {
    const params = new URLSearchParams(window.location.search);
    // Handle Case sensitivity scenarios on query params
    const customQueryParams = new URLSearchParams();
    for (const [name, value] of params) {
        customQueryParams.append(name.toUpperCase(), value.toUpperCase());
    }
    const queryParamValue = customQueryParams.get(queryParam);
    return queryParamValue || Constants.EMPTY_STRING;
};